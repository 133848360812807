import React, { useState } from 'react';

import navLogo from './logo_nav_large.png';
import splashLogo from './logo_splash.png';
import vct_logo from './vct_logo.svg';
import vct_logo_padded from './vct_logo_padded.png';
import sub_logo_padded from './sub_logo_padded.png';

import figure1 from './battery_0.png';
import technologyDiagram from './diagram.svg';
import comparisonTable from './sub_comparison.png';
import ourTeam from './sub_team.jpg';
import ourFacility from './our_facility.png';
import outsideFacility from './outside_facility.jpg';


import aerospaceDrone0 from './drone_0.png';
import electricCarGrowth from './electric_cars_global_sales.png';

import vbond from './v-bond_new.png';
import marrelli from './Carmelo_Marrelli 1.png';
import chen from './chen.jpg';
import aiping from './aiping-yu_edited.png';

import madill from './dave_madill.jpg';
import martin from './rob_0.jpg';
import nursey from './glen_nursey.png';

import coinCellFigure1 from './coin_cell_figure1.png';
import coinCellFigure2 from './coin_cell_figure2.png';
import coinCellFigure3 from './coin_cell_figure3.png';
import coinCellSafetyTest from './coin_cell_safety_test.mp4';

import CMC_1_Image1 from './CMC-1 SOLID STATE ELECTRODE 1.png';
import CMC_1_Image2 from './CMC-1 SOLID STATE ELECTRODE 2.png';

import facility_timeline_drywall from './sub_facility_timeline/20220818_181649.jpg';
import facility_timeline_lab from './sub_facility_timeline/20220818_181845.jpg';
import facility_timeline_lab1 from './sub_facility_timeline/20220818_181917.jpg';
import facility_timeline_inside_empty from './sub_facility_timeline/inside.jpg';
import facility_timeline_incubator from './sub_facility_timeline/incubator.jpg';
import facility_timeline_inside_empty1 from './sub_facility_timeline/inside1.jpg';
import facility_isolator from './isolator.jpg';

import lfp_coin_cell_results from './lfp-coincell-results-may.png';

import sept_2024_graph from './sept_2024_nr_graph.png';
import oct_2024_graph from './oct_2024_nr_graph.png';
import dair_logo from './dair_logo.png';
import feb_2025_graphs from './Feb_2025_Graphs.png';

import nmc811_results from './nmc811-results.png';
import xrd_raman_plots from './xrd_raman_plots.png';

import dair_green_fund from './dair_green_fund.jpeg';

import jan_test_results from './Jan2023Results.png';
import july_test_results from './july_test_results.png';
import project_roadmap from './project_roadmap.png';

import battery_padded from "./battery_padded.png";

import team0 from './team0.png';

import job_posting_0 from './Job Postings/Director_Battery Development_ March_1_April_1 2023.pdf';
import job_posting_1 from './Job Postings/Solid UltraBattery Battery Development Assistant  job posting April 4 2023[1853].pdf';
import job_posting_3 from './Job Postings/Solid UltraBattery Battery Fabrication Process Technician April 8 2024.pdf';

import battery0 from './battery_padded.png';


import pouch_cell from './battery_0.png';
import battery_tech_table from './battery_tech_progression.png';

import './App.css';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


function StyleArrow() {
  return(
    <svg className="StyleArrow" width="34" height="88" viewBox="0 0 34 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2814 84.75C17.704 85.75 16.2607 85.75 15.6833 84.75L2.21182 61.4167C1.63447 60.4167 2.35616 59.1667 3.51086 59.1667L30.4539 59.1667C31.6086 59.1667 32.3303 60.4167 31.7529 61.4167L18.2814 84.75Z" stroke="white"/>
    <path d="M18.2814 55.4167C17.704 56.4167 16.2607 56.4167 15.6833 55.4167L2.21182 32.0833C1.63447 31.0833 2.35616 29.8333 3.51086 29.8333L30.4539 29.8333C31.6086 29.8333 32.3303 31.0833 31.7529 32.0833L18.2814 55.4167Z" stroke="white"/>
    <path d="M18.2347 26.0833C17.6573 27.0833 16.214 27.0833 15.6366 26.0833L2.1651 2.75002C1.58774 1.75002 2.30944 0.500014 3.46413 0.500015L30.4071 0.500017C31.5618 0.500017 32.2835 1.75002 31.7062 2.75002L18.2347 26.0833Z" stroke="white"/>
    </svg>
  );
}

function StyleArrowBottom() {
  return(
    <svg className="StyleArrowBottom" width="34" height="88" viewBox="0 0 34 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2814 84.75C17.704 85.75 16.2607 85.75 15.6833 84.75L2.21182 61.4167C1.63447 60.4167 2.35616 59.1667 3.51086 59.1667L30.4539 59.1667C31.6086 59.1667 32.3303 60.4167 31.7529 61.4167L18.2814 84.75Z" stroke="white"/>
    <path d="M18.2814 55.4167C17.704 56.4167 16.2607 56.4167 15.6833 55.4167L2.21182 32.0833C1.63447 31.0833 2.35616 29.8333 3.51086 29.8333L30.4539 29.8333C31.6086 29.8333 32.3303 31.0833 31.7529 32.0833L18.2814 55.4167Z" stroke="white"/>
    <path d="M18.2347 26.0833C17.6573 27.0833 16.214 27.0833 15.6366 26.0833L2.1651 2.75002C1.58774 1.75002 2.30944 0.500014 3.46413 0.500015L30.4071 0.500017C31.5618 0.500017 32.2835 1.75002 31.7062 2.75002L18.2347 26.0833Z" stroke="white"/>
    </svg>
  );
}

function TopMenu(props) {
  return(
    <div className="TopMenu">
      <img className="Logo" src={navLogo} onClick={()=>{props.setCurrentWindow("home");}}/>
      <div className="Links">
        <div className={"Link dropdown " + ((props.currentWindow == "home" || props.currentWindow == "management" || props.currentWindow == "directors" || props.currentWindow == "team") ? "selected" : "")} onClick={()=>{props.setCurrentWindow("home");}}>
          About <i className="arrow down"></i>
          <div className="dropdown-content">
            <div onClick={(e)=>{props.setCurrentWindow("management"); e.stopPropagation();}}>Management</div>
            <div onClick={(e)=>{props.setCurrentWindow("directors"); e.stopPropagation();}}>Directors</div>
            <div onClick={(e)=>{props.setCurrentWindow("team"); e.stopPropagation();}}>Technical Team</div>
            <div onClick={(e)=>{props.setCurrentWindow("media"); e.stopPropagation();}}>Media</div>
          </div>
        </div>
        <div className={"Link dropdown " + ((props.currentWindow == "technology" || props.currentWindow == "roadmap") ? "selected" : "")} onClick={()=>{props.setCurrentWindow("technology");}}>
          Technology <i className="arrow down"></i>
          <div className="dropdown-content">
            <div onClick={(e)=>{props.setCurrentWindow("roadmap"); e.stopPropagation();}}>Product Roadmap</div>
          </div>
        </div>
        <div className={"Link " + (props.currentWindow == "facility" ? "selected" : "")} onClick={()=>{props.setCurrentWindow("facility");}}>
          Facility
        </div>
        <div className={"Link " + (props.currentWindow == "news_releases" ? "selected" : "")} onClick={()=>{props.setCurrentWindow("news_releases");}}>
          News Releases
        </div>
        <div className={"Link " + (props.currentWindow == "investor" ? "selected" : "")} onClick={()=>{props.setCurrentWindow("investor");}}>
          Investor
        </div>
        <div className={"Link dropdown " + (props.currentWindow == "contact" || props.currentWindow == "careers" ? "selected" : "")} onClick={()=>{props.setCurrentWindow("contact");}}>
          Contact <i className="arrow down"></i>
          <div className="dropdown-content">
            <div onClick={(e)=>{props.setCurrentWindow("careers"); e.stopPropagation();}}>Careers</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function NewsRelease(props) {
  return(
    <div className="NewsRelease">
      <div>
        <div className="NewsReleaseDate">
          {props.date}
        </div>
        <a target="_blank" rel="noreferrer" href={props.url} className="NewsReleaseTitle">
          {props.title}
        </a>
      </div>

      <a target="_blank" rel="noreferrer" href={props.url} className="NewsReleaseButton">
        Read
      </a>
    </div>
  );
}

function HistoryElement(props) {
  // image
  // date
  // writeup
  // left position, or right position

  let textContent = 
  <>
    <div className="historyDate">
      {props.date}
    </div>
    <br/>
    <div className="historyText">
      {props.writeup}
    </div>   
  </>;

  let imageContent = <></>;
  if(props.image !== undefined) {
    imageContent = <>
      <Zoom>
        <img src={props.image} style={props.image_style} className="historyImageHolder"/>
      </Zoom>

    </>;
  }

  if(props.side === "right") {
    return(<>
      <div className="historyItem flipped">
        <div className='historyLine'/>
        <div className='historyPoint'/>
        <div className='leftSide'>
          {imageContent}
        </div>
        <div className='rightSide'>
          {textContent}
        </div>
      </div>
    </>);
  } else {
    return(<>
      <div className="historyItem">
        <div className='historyLine'/>
        <div className='historyPoint'/>
        <div className='leftSide'>
          {textContent}
        </div>
        <div className='rightSide'>
          {imageContent}
        </div>
      </div>
    </>);
  }
}

function HistoryContent(props) {
  return(<>
    <h1>
      Our Timeline
    </h1>
    <div className="historyHolder">
      <div className="historyStart">
        Current Day
        <div className='historyLine'/>
      </div>

      <HistoryElement side="right" date="February 2025 - Test Results with C4V" image={feb_2025_graphs} writeup="Volt Carbon Technologies and Charge CCCV LLC have announced successful test results from two key battery technology collaborations. Volt’s proprietary lithium metal electrolyte, paired with C4V’s advanced BMLMP cathodes, demonstrated exceptional energy density and stable capacity retention beyond 650 cycles. Additionally, Volt’s dry-separated graphite, processed at its Scarborough facility, was integrated into C4V’s Green Anode process, achieving a specific discharge capacity of 363.7 mAh/g with a first-cycle efficiency of 93.6%. These results validate the potential of both innovations in advancing sustainable, high-performance energy storage solutions. The companies are now advancing to pouch cell development, with the aim of scaling these technologies for larger-format batteries in electric vehicles and grid storage applications."/>

      <HistoryElement side="left" date="November 2024 - DAIR Funding (2 Projects)" image={dair_logo} writeup="Volt Carbon Technologies, along with its subsidiary Solid UltraBattery, has been awarded funding from the DAIR Green Fund for two aerospace innovation projects. The first initiative focuses on developing high-performance carbon materials, leveraging Volt Carbon’s proprietary graphite extraction technology to create aerospace-grade graphene composites. These materials enhance aircraft fuel efficiency and thermal management, supporting Canada’s net-zero aviation goals. The second project, led by Solid UltraBattery, aims to improve the low-temperature performance of lithium-ion batteries, particularly for unmanned aerial vehicles (UAVs). Through controlled ground testing and collaboration with the University of Waterloo, the company seeks to develop reliable battery solutions for extreme cold environments. Both initiatives highlight Volt Carbon’s commitment to advancing sustainable energy solutions for the aerospace industry."/>
      <HistoryElement side="right" date="October 2024 - LFP / Li-metal, 1100 Cycles" image={oct_2024_graph} writeup="Volt Carbon Technologies has achieved a major milestone, reaching 1,100 cycles in testing its proprietary Lithium Iron Phosphate (LFP) / Li-metal battery with its internally developed electrolyte. Manufactured at the Solid UltraBattery facility in Guelph, Ontario, these batteries retain 80% of their capacity after 1,100 cycles, marking significant progress from the previously reported 800 cycles. This advancement strengthens the competitiveness of LFP / Li-metal chemistries by improving cycle life while eliminating the need for costly nickel and cobalt materials. Volt Carbon continues to refine its battery technology, aiming for even higher cycle life and capacity retention, while scaling production at its Guelph facility to meet the increasing demand for advanced energy storage solutions."/>
      <HistoryElement side="left" date="September 2024 - Low Temperature Breakthrough" image={sept_2024_graph} writeup="Volt Carbon Technologies has announced a breakthrough in lithium-ion battery performance, successfully developing a low-temperature Li-metal battery functional at -80°C. In collaboration with Dr. Aiping Yu from the University of Waterloo, the team at Solid UltraBattery has created a proprietary high-entropy electrolyte that significantly enhances battery performance in extreme cold. Testing demonstrated that at -40°C, the new electrolyte retained 62.5% capacity, compared to 16.8% for standard electrolytes, and at -80°C, it maintained 30.3% capacity while conventional electrolytes failed entirely. This advancement holds potential for applications in aerospace, defense, and extreme cold environments. The company is now advancing to pouch cell development to validate commercial scalability and aims to bring this technology to market for industries requiring superior cold-weather battery performance."/>
      <HistoryElement side="right" date="July 2024 - IPON Patent Funding, 2 Patent Applications Funded" writeup="Volt Carbon Technologies has announced its selection as a client of Intellectual Property Ontario (IPON) and the receipt of $60,425 in funding to support its intellectual property development. This funding has enabled the submission of two new patent applications for the company’s proprietary lithium-ion battery electrolyte technology. With this addition, Volt Carbon's intellectual property portfolio now comprises five patent applications, covering both advanced battery technology and a proprietary graphite dry separation process. Additionally, the funding has facilitated a comprehensive patent landscaping report, providing valuable market intelligence on solid-state lithium-ion batteries. Volt Carbon aims to leverage this support to solidify its position in the energy storage sector and drive innovation in high-energy, safe battery solutions."/>

      <HistoryElement side="left" date="May 2024 - LFP / Li-metal, 800 Cycles" image={lfp_coin_cell_results}writeup="Solid UltraBattery has released initial test results for its Lithium Iron Phosphate (LFP) battery, showcasing advancements in high-energy LFP technology. Achieving an industry-leading 280Wh/kg for LFP/Li-metal batteries, the company's recent testing demonstrates coin cells enduring 800 cycles while maintaining 78.5% of their capacity, signaling a significant milestone in LFP/Li-metal battery technology. With a goal to surpass 800 cycles and achieve an 80% capacity threshold by the end of 2024, the company remains committed to advancing its lithium-ion battery product roadmap and developing battery-grade anodes processed from its Scarborough facility."/>
      <HistoryElement side="right" date="December 2023 - NMC811 (CNT) / Li-metal, 500 Cycles" image={nmc811_results} writeup="Solid Ultrabattery's Guelph facility has achieved a significant milestone, surpassing its 2023 target of 500 cycles for its lithium-ion battery. By incorporating composite electrolytes and utilizing carbon nanotubes (CNTs) in the cathode, the company achieved improved cycle life exceeding 500 cycles, with a retention capacity of 87.7% at the 514th cycle. Looking ahead, the company aims to exceed 800 cycles and attain 80% capacity in 2024, with plans underway to implement Phase Two, involving the construction of a pilot battery line and dry room to upscale production for specialized consumer and industrial applications."/>
      <HistoryElement side="left" date="December 2023 - Air Classifier 98% Purity" image={xrd_raman_plots} writeup="Volt Carbon Technologies has initiated battery anode development using graphite from Green Battery Minerals' Berkwood property, achieving a record-high total carbon content of 98.4% through a dry separation process. Under Dr. Aiping Yu's guidance, the graphite was mechanically reduced to battery-grade anode sizes, confirmed suitable for further development through extensive characterization. Coin cells, manufactured alongside benchmarked samples, are undergoing cycle testing at both Solid Ultrabattery's and the University of Waterloo's labs, showing promising initial results with a capacity of 344mAh/g, highlighting the potential of sustainable dry-separated graphite for battery applications."/>
      <HistoryElement side="right" date="November 2023 - Stategic Relationship with C4V" writeup="Volt Carbon Technologies Inc. (VCT) has entered into a Material Transfer Agreement with Charge CCCV LLC (C4V), allowing for the transfer of samples of VCT's Solid Ultrabattery proprietary electrolyte, for testing and qualification purposes. "/>
      <HistoryElement side="left" date="October 2023 - Horizon Fund (1 Project)" writeup="Solid Ultrabattery Inc. is a successful applicant of the Horizon Fund Competition for a project titled “Development of Solar Charging for Solid Ultrabattery’s Aerospace Battery Project”. The project aims to further enhance Solid Ultrabattery’s new 400Wh/kg battery with solar charging. The combination of solar and battery electric technology holds the potential to significantly extend the flight duration of small drones."/>
      <HistoryElement side="right" date="July 2023 - NMC811 / Li-metal, 370 Cycles" image={july_test_results}  writeup="Volt Carbon Technologies Releases Mid Year Test Results for its Lithium-ion Battery and Provides Operational Update. The battery pouch cells were manufactured using the Company's proprietary technology, which incorporates
composite electrolytes to enhance cycle life and battery stability. These cells were assembled with the Company's 
custom electrolytes and membranes, combined with the high-energy cathode NMC811 and lithium metal, resulting in
fabrication of the advanced battery cells.
In summary, the lithium metal pouch cells have demonstrated substantially improved performance with 370 cycles at
nearly 100% capacity. These results significantly surpass the previous findings from coin cell data,
which showed 400 cycles at 81.8%."/>
      <HistoryElement side="left" date="May 2023 - 5 Amp-hour Pouch Cell" image={battery_padded} writeup="Under the Leadership of Dr. Zhongwei Chen, University of Waterloo and Solid Ultrabattery's battery lead, Dr. Hey Woong Park, the first batch of 19 layer 5 Ah batteries was fabricated at the Guelph facility. The batteries reached their initial targeted energy density exceeding 250 Wh/kg (which matches or exceeds the same energy density levels of current electric vehicles in production). In testing, the batteries have successfully completed rate capability tests and is currently performing cycle life tests at the Guelph lab."/>
      <HistoryElement side="right" date="March 2023 - DAIR Funding (1 Project)" image={dair_green_fund}  writeup="Solid UltraBattery has been recognized as a top eight finalist by Downsview Aerospace Innovation & Research (DAIR) Green Fund to develop lithium-ion batteries for small drone and unmanned aerial vehicle (UAV) applications. The DAIR Green Fund, which is funded by the Federal Economic Development Agency for Southern Ontario (FedDev Ontario), will support 75% of the total project cost up to $75,000, while Solid UltraBattery Inc. will contribute the remaining 25%. The project aims to develop a lithium-ion battery that meets the rigorous demands of aerospace applications starting with a small 5Ah cell as a single building block. The project will be initially trialed on a small drone weighing under 1 kg. The project is expected to be completed by March 31, 2024."/>
      <HistoryElement side="left" date="January 2023 - MOF / Li-metal, 400 Cycles" image={jan_test_results} writeup="Solid UltraBattery releases its 2022 test results on the performance of its solid-state lithium-metal batteries. The battery cells were fabricated using the company’s proprietary technology which includes a metal organic framework (MOF) membrane and composite electrolytes. The lithium metal coin cell batteries achieved 400 cycles at 81.6% capacity, and the lithium metal pouch cell batteries achieved 265 cycles at 81.8%. The company aims to exceed 800 cycles and 80% capacity with its safe lithium metal battery technology through further development of MOF nanotechnology and composite electrolytes."/>
      <HistoryElement side="right" date="May 2022" image={outsideFacility} writeup="Solid UltraBattery successfully produces the first batch of 8-layer lithium-ion pouch cells using NMC811 cathode chemistry paired with a graphite anode. The cells have shown high capacity retention during preliminary internal testing."/>
      <HistoryElement side="left" date="December 2021" image={facility_timeline_lab} writeup="Solid UltraBattery commissions its new research facility fully equipped to build and test prototype batteries."/>
      <HistoryElement side="right" date="July 2021" image={facility_timeline_inside_empty1} writeup="Solid UltraBattery commences construction of its new Battery Research Facility at 590 Hanlon Creek Blvd Guelph, Ontario."/>
      <HistoryElement side="left" date="May 2021" image={vct_logo_padded} image_style={{aspectRatio : "unset", objectFit : "unset"}} writeup="Volt Carbon Technologies acquires Solid UltraBattery, bringing together their expertise in the field of energy storage technologies with Volt Carbon’s critical minerals processing capabilities. With this acquisition, Solid UltraBattery strengthens its position in developing advanced solid-state battery technology."/>
      <div className="historyUpdate">
        Acquired 2021 by VCT
        <div className='historyLine'/>
      </div>
      <HistoryElement side="right" image={sub_logo_padded} image_style={{aspectRatio : "unset", objectFit : "unset"}}  date="February 2019" writeup="After 5 Years of Solid State Battery research, Solid UltraBattery Inc. is founded by Dr. Zhongwei Chen in February 2019. Dr. Chen is a Canada Research Chair and Professor at the University of Waterloo, and has many patents in the field of Advanced Materials and Clean Energy. He founded Solid UltraBattery with the focus on R&D, design, and production of low-cost, high-energy, and safe solid-state batteries." />
      <div className="historyStart">
        Founded 2019
        <div className='historyLine'/>
      </div>
    </div>
  </>);
}

function TopSplash() {
  return (
    <div className="TopSplash">
      <StyleArrow/>
      <StyleArrowBottom/>
      <img className="Logo" src={splashLogo}/>
      <div className="Intro-Text">
      </div>
    </div>
  );
}

function SmallInfo(props) {
  return (
    <div className="SmallInfo">
      {props.content}
    </div>
  );
}

function MainInfo(params) {

  let classes = "MainInfo " + params.color;

  let leftSide = 
    <div className="Column">
      {params.contentLeft}
    </div>;

  let rightSide =
  <div className="Column" style={params.contentRightStyle}>
    {params.contentRight}
  </div>;

  if(params.contentLeft != undefined && params.contentRight != undefined) {
    return(
      <div className={classes}>
        <StyleArrow/>
        <StyleArrowBottom/>
        {leftSide}
        {rightSide}
  
      </div>
    );
  } else {
    return(
      <div className={classes}>
        <StyleArrow/>
        <StyleArrowBottom/>
        <div className="Full">
          {params.contentLeft}
        </div>
      </div>
    );
  }
}

function Footer() {
  return(
    <div className="Footer">
      All rights reserved Solid UltraBattery {new Date().getFullYear()}.
    </div>
  );
}

function App() {

  const [currentWindow, setCurrentWindow] = useState("home");


  let contactContent = 
  <MainInfo color={"white"} contentLeft={
    <>
      <h1>
      Contact
      </h1>
  
      <h2>
      Battery Fabrication Facility
      </h2>
  
      <div>
        590 Hanlon Creek Blvd Unit 7 <br/>
        Guelph, Ontario <br/>
        info@voltcarbontech.com
      </div>
    </>
  }
  
  contentRight={
    <>
      <img src={navLogo}/>
    </>
  }/>;

  let subsidiaryContent = 
  <>
    <MainInfo color={"white"}
      contentLeft={
        <>
          <h1>
            We're a subsidiary of Volt Carbon Technologies
          </h1>

          <h2>
            <a href="https://ca.finance.yahoo.com/quote/VCT.V/" target="_blank" rel="noreferrer">TSX-V: VCT</a> &nbsp; &nbsp;
            <a href="https://finance.yahoo.com/quote/TORVF/" target="_blank" rel="noreferrer">OTCQB: TORVF</a> &nbsp; &nbsp;
            <a href="https://www.boerse-berlin.com/index.php/Shares?isin=CA92873M1023" target="_blank" rel="noreferrer">BERLIN:WNF</a>
          </h2>

          <div>
            <a href="https://voltcarbontech.com/" target="_blank" rel="noreferrer">Volt Carbon Technologies (TSXV – VCT)</a> is a junior resource company involved in graphite purification, battery development, acquisition and exploration of property interests that are considered potential sites for future mining opportunities. The Company continues to hold mineral rights to multiple historic molybdenum properties in British Columbia, graphite properties in Ontario and Quebec and a grassroots lithium property in Ontario.              <br/>
          </div>
        </>
      }
      contentRight={
        <>
          <a href="https://voltcarbontech.com/" target="_blank" rel="noreferrer"><img src={vct_logo}/></a>
        </>
      }
      contentRightStyle={
        {        
          display : 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }
      }
    />  
  </>;

  let content = <></>;       


  switch(currentWindow) {

    case "investor":
        content= <> 
          <MainInfo color={"white"}
            contentLeft={
              <>
                <h1>
                Investor
                </h1>

                <br/>
                <br/>

                <h2>
                  We're a Volt Carbon Technologies subsidiary.
                </h2>

                <h3>
                  Our symbols: &ensp;
                  <a href="https://ca.finance.yahoo.com/quote/VCT.V/" target="_blank" rel="noreferrer">TSX-V: VCT</a> &nbsp; &nbsp;
                  <a href="https://finance.yahoo.com/quote/TORVF/" target="_blank" rel="noreferrer">OTCQB: TORVF</a> &nbsp; &nbsp;
                  <a href="https://www.boerse-berlin.com/index.php/Shares?isin=CA92873M1023" target="_blank" rel="noreferrer">BERLIN:WNF</a>
                </h3>

                <br/>

                <h2>
                Events
                </h2>

                <a className="Button" href="https://voltcarbontech.com/wp-content/uploads/2023/03/OAC-BtB-event-DAIR-project-pitches-2023-03-26.pdf" target="_blank">
                  Fifth Annual Ontario Aerospace Council R&T Event - Solid UltraBattery's 5Ah battery project
                </a>                 
                <br/>
                <br/>

                <h2>
                Articles & Presentations
                </h2>

                <a className="Button" href="https://voltcarbontech.com/wp-content/uploads/2022/05/VCT-A-competitive-advantage-for-next-generation-solid-state-electrolyte-batteries.pdf" target="_blank">
                  Article: A competitive advantage for next-generation solid-state electrolyte batteries
                </a>                 
                <br/>
                <br/>
                <a className="Button" href="https://voltcarbontech.com/wp-content/uploads/2023/02/Volt-Carbon-Tech-2023-Feb-2-Final1555.pdf" target="_blank">
                  Investor Present, February 2023
                </a>
                <br/>
                <br/>

                <h2>
                Financial Reports & Shareholder Information
                </h2>

                <a className="Button" href="https://voltcarbontech.com/wp-content/uploads/2023/02/MDA-Oct-31-2022.pdf" target="_blank">
                  MD&A - October 31, 2022
                </a>

                <br/>
                <br/>

                <h2>
                Transfer Agents & Registrars
                </h2>

                <div>
                  <h3>Auditor</h3>
                  Kenway Mack Slusarchuk Stewart LLP <br/>
                  1500, 333 - 11 Avenue SW <br/>
                  Calgary, AB T25 1L9 <br/>
                  Tel: 403-233-7750 <br/>
                  Fax: 403-266-5267 <br/>
                </div>
                <br/>
                <div>
                  <h3>Legal Counsel</h3>
                    MLT Aikins LLP <br/>
                    2100 Livingston Place <br/>
                    222 - 3rd Avenue SW <br/>
                    Calgary, AB T2P 0B4 <br/>
                    Tel: 403-693-4300 <br/>
                    Fax: 403-508-4349 <br/>
                </div>
                <br/>
                <div>
                  <h3>Registrar & Transfer Agent</h3>
                  Computershare Trust Company Of Canada <br/>
                  Tel: (1) 604-661-9400 <br/>
                  Fax: (1) 604-661-9549 <br/>
                </div>

                <br/>
                <br/>
                
                <h2>
                  Regulatory Filings
                </h2>

                <div>
                  Regulatory Filings at: <a href="https://www.sedar.com/" target="_blank">www.sedar.com</a>
                </div>
                <br/>
                <h2>
                  Annual Stockholders Meeting and Proxy Materials
                </h2>

                <a className="Button" href="https://voltcarbontech.com/wp-content/uploads/2022/02/2020_Information_Circular_Final.pdf"  target="_blank">
                  NOTICE OF ANNUAL AND SPECIAL MEETING OF SHAREHOLDERS
                </a>
              </>
            }
          />
        </>
      break;

    case "facility":
      content=
      <>
        <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
              Our Facility
              </h1>

              <h2>
              Battery R&D Center
              </h2>

              <div>
              Solid UltraBattery is proud to announce the successful completion of its new state-of-the-art 10,000 ft<sup>2</sup> Research & Development facility located at 590 Hanlon Creek Blvd. in Guelph, Ontario. The facility was constructed with the latest technologies and is equipped with cutting-edge prototype and test equipment to ensure the development of top-quality batteries.
              <br/><br/>
              Since its completion in December 2021, the facility has been operational, producing small batch runs of coin cells and pouch cells. As part of the company's commitment to providing the highest quality research, all prototype and test equipment has been commissioned and tested to ensure accuracy and efficiency in the R&D process.
              </div>
              <br/>
              <Zoom>
                <img src={facility_timeline_lab}/>
              </Zoom>
            </>
          }
          contentRight={
            <>
              <Zoom>
                <img src={ourFacility}/>
              </Zoom>
              <br/>              
              <br/>
              <Zoom>
                <img src={facility_isolator}/>
              </Zoom>
            </>
          }
        />     
      </>;
      break;

      case "news_releases":
        content=
        <>
          <MainInfo color={"white"} 

            contentLeft={
              <>
                <h1>
                  News Releases
                </h1>
                <br/>
                <div className="NewsReleaseHolder">


                  <NewsRelease date="2025-01-21" title="Volt Carbon Technologies to Exhibit at PDAC 2025 and Present at Corporate Presentation Forum for Investors" url="https://voltcarbontech.com/wp-content/uploads/2025/01/2025-1-21.pdf"/>
                  <NewsRelease date="2024-11-15" title="Volt Carbon’s Mineral Processing Facility in Toronto and its Subsidiary, Solid UltraBattery in Guelph Awarded DAIR Green Fund for Aerospace Innovation Projects" url="https://voltcarbontech.com/wp-content/uploads/2024/11/2024-11-15.pdf"/>
                  <NewsRelease date="2024-10-28" title="Volt Carbon Technologies Reaches 1,100 Cycles with its High Energy Density Lithium Iron Phosphate / Li-metal Battery" url="https://voltcarbontech.com/wp-content/uploads/2024/10/2024-10-28.pdf"/>
                  <NewsRelease date="2024-09-16" title="Volt Carbon Technologies Announces the Development of a Low Temperature Limetal Battery Functional to -80°C" url="https://voltcarbontech.com/wp-content/uploads/2024/09/2024-9-16.pdf"/>
                  <NewsRelease date="2024-07-09" title="Volt Carbon Technologies Announces IP Funding from Intellectual Property Ontario and Submission of Two Patent Applications for Battery Electrolyte" url="https://voltcarbontech.com/wp-content/uploads/2024/07/2024-7-9.pdf"/>
                  <NewsRelease date="2024-05-08" title="Volt Carbon Technologies Releases Test Results for its High Energy Density Lithium (Li)-metal Iron Phosphate Battery and Provides Operational Update" url="https://voltcarbontech.com/wp-content/uploads/2024/05/2024-5-8.pdf"/>

                  <NewsRelease date="2024-02-08" title="Volt Carbon Technologies Enhances Dry Tailings Byproducts from Graphite Separation into Iron Concentrate and provides AGM Update" url="https://voltcarbontech.com/wp-content/uploads/2024/02/2024-2-8.pdf"/>
                  <NewsRelease date="2023-12-13" title="Volt Carbon Technologies Achieves 2023 Target and Record Milestone in its Lithium-Metal Battery Development" url="https://voltcarbontech.com/wp-content/uploads/2023/12/2023-12-13.pdf"/>
                  <NewsRelease date="2023-12-11" title="Volt Carbon Technologies Launches Battery Testing with Exclusive Dry-Separated Graphite Sourced from GEM’s Property and Releases Preliminary Test Data" url="https://voltcarbontech.com/wp-content/uploads/2023/12/2023-12-11.pdf"/>
                  <NewsRelease date="2023-12-04" title="Volt Carbon Technologies Welcomes Dr. Aiping Yu as Newest Board Director, acknowledges Dr Zhongwei Chen’s Resignation and Grants Options" url="https://voltcarbontech.com/wp-content/uploads/2023/12/2023-12-04.pdf"/>
                  <NewsRelease date="2023-11-17" title="Volt Carbon Technologies and Charge CCCV LLC Announces Strategic Relationship in the Supply of Battery Electrolyte and Graphite" url="https://voltcarbontech.com/wp-content/uploads/2023/11/2023-11-17.pdf"/>
                  <NewsRelease date="2023-10-25" title="Volt Carbon Technologies Announces Subsidiary Solid Ultrabattery as a Successful Applicant of the Horizon Fund Competition" url="https://voltcarbontech.com/wp-content/uploads/2023/10/2023-10-25.pdf"/>

                  <NewsRelease date="2023-07-24" title="Volt Carbon Technologies Releases Mid Year Test Results for its Lithium-ion Battery and Provides Operational Update" url="http://voltcarbontech.com/wp-content/uploads/2023/07/2023-7-24.pdf"/>
                  <NewsRelease date="2023-05-23" title="Volt Carbon Technologies Builds its First 5Ah Lithium-Ion Batteries to Support the DAIR Green Funded Aerospace Project" url="http://voltcarbontech.com/wp-content/uploads/2023/05/2023-5-23.pdf"/>

                  <NewsRelease date="2023-03-14" title="Volt Carbon Technologies Announces Subsidiary Solid UltraBattery as a Successful Applicant of the DAIR Green Fund" url="https://voltcarbontech.com/wp-content/uploads/2023/03/2023-3-13.pdf"/>
                  <NewsRelease date="2023-01-17" title="Volt Carbon Technologies Releases Test Results for its Lithium-Metal Battery Technology" url="https://voltcarbontech.com/wp-content/uploads/2023/01/2023-1-17.pdf"/>
                  <NewsRelease date="2022-05-05" title="Volt Carbon Technologies Announces First Batch Builds of 8 Layer Lithium-Ion Batteries and Preliminary Cycle Test Data" url="https://voltcarbontech.com/wp-content/uploads/2022/05/2022-05-05.pdf"/>
                  <NewsRelease date="2021-11-11" title="Saint Jean Carbon Releases Test Data for its Solid Electrolyte Battery Technology and Provides Operational Update" url="https://voltcarbontech.com/2021/11/11/saint-jean-carbon-releases-test-data-for-its-solid-electrolyte-battery-technology-and-provides-operational-update/"/>
                  <NewsRelease date="2021-09-27" title="Saint Jean Carbon Provides Update On Solid UltraBattery Facility" url="https://voltcarbontech.com/2021/09/27/saint-jean-carbon-provides-update-on-solid-ultrabattery-facility/"/>
                  <NewsRelease date="2021-08-19" title="Saint Jean Carbon Provides Progress Updates on the Solid UltraBattery Plant, Mineral Processing, Carbon Sciences Research and OTCQB Listing" url="https://voltcarbontech.com/2021/08/19/saint-jean-carbon-provides-progress-updates-on-the-solid-ultrabattery-plant-mineral-processing-carbon-sciences-research-and-otcqb-listing/"/>
                  <NewsRelease date="2021-07-06" title="Solid UltraBattery Releases Preliminary Test Data on the Safety of it’s Lithium-metal Battery Solid State Electrolyte" url="https://voltcarbontech.com/2021/07/06/solid-ultrabattery-releases-preliminary-test-data-on-the-safety-of-its-lithium-metal-battery-solid-state-electrolyte/"/>
                  <NewsRelease date="2021-06-15" title="Saint Jean Carbon Provides Operational Update For Solid UltraBattery" url="https://voltcarbontech.com/2021/06/15/saint-jean-carbon-provides-operational-update-for-solid-ultrabattery/"/>

                  <NewsRelease date="2021-05-28" title="Saint Jean Carbon Closes Acquisition Of Solid UltraBattery" url="https://voltcarbontech.com/2021/05/28/saint-jean-carbon-closes-acquisition-of-solid-ultrabattery/"/>
                  <NewsRelease date="2021-04-30" title="Saint Jean Carbon Receives Approval For Acquisition Of Solid UltraBattery" url="https://voltcarbontech.com/2021/04/30/saint-jean-carbon-receives-approval-for-acquisition-of-solid-ultrabattery/"/>
                  <NewsRelease date="2021-04-08" title="Saint Jean Carbon Receives Conditional Approval For Acquisition Of Solid UltraBattery" url="https://voltcarbontech.com/2021/04/08/saint-jean-carbon-receives-conditional-approval-for-acquisition-of-solid-ultrabattery/"/>

                  <NewsRelease date="2021-03-16" title="Saint Jean Carbon Provides Second Update Regarding Transaction With Solid UltraBattery" url="https://voltcarbontech.com/2021/03/16/saint-jean-carbon-provides-second-update-regarding-transaction-with-solid-ultrabattery/"/>
                  <NewsRelease date="2021-03-12" title="Saint Jean Carbon Provides Update Regarding Transaction With Solid UltraBattery" url="https://voltcarbontech.com/2021/03/12/saint-jean-carbon-provides-update-regarding-transaction-with-solid-ultrabattery/"/>
                  <NewsRelease date="2021-02-10" title="Saint Jean Carbon Announces Intent To Purchase All The Shares of Solid UltraBattery Inc." url="https://voltcarbontech.com/2021/02/10/saint-jean-carbon-announces-intent-to-purchase-all-the-shares-of-solid-ultrabattery-inc/"/>
                </div>
              </>
            }
          />
        </>;
        break;

    case "technology":
      content=
      <>
        <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
              Solid State Lithium Metal Batteries
              </h1>
              <br/>

              <div>
              Solid UltraBattery is currently in the process of developing a revolutionary solid-state battery (SSB) technology that utilizes a solid electrolyte separator, resulting in a lighter weight and higher energy density battery. This cutting-edge technology features a solid electrolyte separator positioned next to a lithium metal current collector.
              <br/>
              </div>
              <br/>
              <img style={{maxWidth : "512px"}} src={technologyDiagram}/>
              <br/>
              <br/>
              <br/>
              <br/>
              <table>
                <tbody>
                <tr>
                  <th>
                    Generation
                  </th>
                  <td>
                    <b>Gen 0,<br/> 1990s</b>
                  </td>
                  <td>
                  <b>Gen 1, (Present)<br/> 2010s</b>
                  </td>
                  <td>
                  <b>Gen 2,<br/> 2020s</b>
                  </td>
                  <td>
                  <b>Gen 3,<br/> 2025 onwards</b>
                  </td>
                </tr>
                <tr>
                  <th>
                    Configuration
                  </th>
                  <td>
                    <table className='battery-table-configuration'>
                      <tbody>
                        <tr>
                          <td>Thick Li-Metal</td>
                        </tr>
                        <tr>
                          <td>Separator</td>
                        </tr>
                        <tr>
                          <td>Cathode<br/> (TiS<sub>2</sub>)</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table className='battery-table-configuration'>
                      <tbody>
                        <tr>
                          <td>Carbon Anode</td>
                        </tr>
                        <tr>
                          <td>Separator</td>
                        </tr>
                        <tr>
                          <td>Cathode<br/> (LCI, LFP, NMC)</td>
                        </tr>
                      </tbody>
                    </table>                   
                  </td>

                  <td>
                    <table className='battery-table-configuration'>
                      <tbody>
                        <tr>
                          <td>Si-composite Anode</td>
                        </tr>
                        <tr>
                          <td>Separator</td>
                        </tr>
                        <tr>
                          <td>Cathode<br/> (NMC, NCA)</td>
                        </tr>
                      </tbody>     
                    </table>                                  
                  </td>
                  <td>
                    <table className='battery-table-configuration special'>
                      <tbody>
                        <tr>
                          <td>Thin Li-Metal</td>
                        </tr>
                        <tr>
                          <td>Separator/Electrolyte</td>
                        </tr>
                        <tr>
                          <td>Cathode<br/> (NMC, S)</td>
                        </tr>
                      </tbody>     
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>
                    Type
                  </th>
                  <td>
                    Li-Metal
                  </td>
                  <td>
                    Li-ion
                  </td>
                  <td>
                    Li-ion
                  </td>
                  <td>
                    Li-Metal
                  </td>
                </tr>
                <tr>
                  <th>
                    Energy Density
                  </th>
                  <td>
                    100-200 Wh/kg
                  </td>
                  <td>
                    200-250 Wh/kg
                  </td>
                  <td>
                    250-300 Wh/kg
                  </td>
                  <td>
                    300-500 Wh/kg
                  </td>
                </tr>
                <tr>
                  <th>
                    Safety
                  </th>
                  <td>
                    <div className='tableBoxFlex'>
                      <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 10V12"></path>
                        <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                      </svg>
                      <span>
                        Dangerous
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className='tableBoxFlex'>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4.75L4.75002 8C4.75002 8 4.00002 19.25 12 19.25C20 19.25 19.25 8 19.25 8L12 4.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M12.5 15C12.5 15.2761 12.2761 15.5 12 15.5C11.7239 15.5 11.5 15.2761 11.5 15C11.5 14.7239 11.7239 14.5 12 14.5C12.2761 14.5 12.5 14.7239 12.5 15Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M12 9V11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                      <span>
                        Improved safety
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className='tableBoxFlex'>
                      <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                      </svg>
                      <span>
                        Safer
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className='tableBoxFlex'>
                      <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                      </svg>
                      <span>
                        Safer
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </>
          }
        />

        <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
              Our Technology
              </h1>

              <h2>
              Reduced charge times, increased cycle life, and lower cost
              </h2>

              <div>
              Compared to traditional lithium-ion batteries, our management team at Solid UltraBattery anticipates that our SSBs will offer numerous advantages. These benefits include a lower carbon footprint, increased recyclability, and simplified manufacturing processes. We believe that this breakthrough technology has the potential to significantly improve the performance and sustainability of battery technology.
              </div>

              <br/>

              <table>
                <tbody>
                  <tr>
                    <th>
                      Performance Requirements
                    </th>
                    <th>
                      Leading Competitor
                    </th>
                    <th>
                      Our Technology <sup>1</sup>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="36" height="36" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M8.25 6.75H6.75C5.64543 6.75 4.75 7.64543 4.75 8.75V15.25C4.75 16.3546 5.64543 17.25 6.75 17.25H7.25M14.75 6.75H15.25C16.3546 6.75 17.25 7.64543 17.25 8.75V15.25C17.25 16.3546 16.3546 17.25 15.25 17.25H13.75"></path>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.75 10.75H18C18.6904 10.75 19.25 11.3096 19.25 12V12C19.25 12.6904 18.6904 13.25 18 13.25H17.75"></path>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M11.75 6.75L8.75 12H13.25L10.25 17.25"></path>
                        </svg>
                        <span>
                        Charge Rate
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                        </svg>
                        <span>
                          4C fast charge ({"<"}15 minutes)
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                        </svg>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                        </svg>
                        <span>
                          5C fast charge ({"<"}12 minutes)
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="36" height="36" fill="none" viewBox="0 0 24 24">
                          <circle cx="12" cy="12" r="7.25" stroke="currentColor" strokeWidth="1.5"></circle>
                          <path stroke="currentColor" strokeWidth="1.5" d="M12 8V12L14 14"></path>
                        </svg>

                        <span>
                          Cycle Life
                        </span>
                      </div>
                      
                    </td>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                        </svg>
                        <span>
                          {">"}800 cycles
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                        </svg>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                        </svg>
                        <span>
                        {">"}1000 cycles
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="36" height="36" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4.75 8.75C4.75 7.64543 5.64543 6.75 6.75 6.75H15.25C16.3546 6.75 17.25 7.64543 17.25 8.75V15.25C17.25 16.3546 16.3546 17.25 15.25 17.25H6.75C5.64543 17.25 4.75 16.3546 4.75 15.25V8.75Z"></path>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7.75 9.75V14.25"></path>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M11 9.75V14.25"></path>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M14.25 9.75V14.25"></path>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.75 10.75H18C18.6904 10.75 19.25 11.3096 19.25 12V12C19.25 12.6904 18.6904 13.25 18 13.25H17.75"></path>
                        </svg>

                        <span>
                          Energy
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                        </svg>
                        <span>
                        350-450 Wh/kg
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                        </svg>
                        <span>
                        350-450 Wh/kg
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="36" height="36" fill="none" viewBox="0 0 24 24">
                          <circle cx="12" cy="12" r="7.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></circle>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M14.25 8.75H11.375C10.4775 8.75 9.75 9.47754 9.75 10.375V10.375C9.75 11.2725 10.4775 12 11.375 12H12.625C13.5225 12 14.25 12.7275 14.25 13.625V13.625C14.25 14.5225 13.5225 15.25 12.625 15.25H9.75"></path>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 7.75V8.25"></path>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 15.75V16.25"></path>
                        </svg>

                        <span>
                          Cost
                        </span>
                      </div>    
                      
                    </td>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.25 6.75L6.75 17.25"></path>
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6.75 6.75L17.25 17.25"></path>
                        </svg>
                        <span>
                          Higher cost (inorganic SSE)
                        </span>
                      </div>                      
                    </td>
                    <td>
                      <div className='tableBoxFlex'>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                        </svg>
                        <span>
                          Lower cost (composite SSE)
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="note">
                <sup>1</sup> Estimates and results are based on laboratory tests only and such results or estimates, as applicable, may not be replicated, scaled or result in the same estimates, measurements, or results in commercial utilizations. 
              </div>
            </>
          }
        
        />

<MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
              Mid Year Test Results for its Lithium-ion Battery
              </h1>

              <h2>
                Test data from Solid UltraBattery’s NMC811 Lithium Metal Pouch Cell battery.
              </h2>

              <div>
              The battery pouch cells were manufactured using the Company's proprietary technology, which incorporates
composite electrolytes to enhance cycle life and battery stability. These cells were assembled with the Company's
custom electrolytes and membranes, combined with the high-energy cathode NMC811 and lithium metal, resulting in
fabrication of the advanced battery cells.
                <br/>
                <br/>
                In summary, the lithium metal pouch cells have demonstrated substantially improved performance with 370 cycles at
nearly 100% capacity. These results significantly surpass the previous findings from coin cell data,
which showed 400 cycles at 81.8% capacity and were reported earlier this year.
                <br/>
                <br/>
                <img src={july_test_results}/>
                <br/>
                <div style={{textAlign: "center"}}>
                  NMC811 Lithium Metal Pouch Cell, Charge/Discharge data, 3 voltages, C/3, 25 °C              
                </div>
              </div>
              <br/>
            </>
          }
        />

        <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
              LiFePO4 Coin Cells
              </h1>

              <h2>
                Initial test data from batteries built using Solid
                Ultrabattery’s proprietary solid electrolyte battery.
              </h2>

              <div>
                The results of 3000 charge/discharge cycles from our LiFePO4 coin cells is shown in the figures below. The capacity retention of these cells indicate a high Coulombic efficiency (CE) close to 100% as shown in Figure 1.
                <br/>
                <br/>
                <img src={coinCellFigure1}/>
                <br/>
                After 3000 cycles of testing at C-rates of 0.2C and 1C charge/discharge, the cells maintained capacity retention of 80% as shown in Figure 2.
                <br/>
                <img src={coinCellFigure2}/>
                <br/>
                As depicted in Figure 3, Solid UltraBattery’s cells were tested at C-rates varied between 0.5C to 10C. The 5C rate was observed to sustain 80% capacity retention at 3000 cycles.
                <br/>
                <img src={coinCellFigure3}/>
                <br/>
                The first pouch cells fabricated with Solid UltraBattery’s solid electrolyte technology were made using an NMC chemistry and tested for safety against perforations by subjecting the pouch cell to a nail penetration test. The video below shows a view of the pouch cell during the penetration test. It was observed that Solid UltraBattery’s cells did not catch fire or exhibit any thermal runaway during the safety testing, as seen in the video below.
              </div>
              <br/>
              <video autoPlay muted controls loop>
                        <source src={coinCellSafetyTest} type="video/mp4"/>
                        Your browser does not support the video tag.
              </video>
            </>
          }
        />

        <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
              CMC-1 Solid State Electrolyte
              </h1>

              <h2>
              Thermal Stability of Solid Ultra Battery’s CMC-1 Membrane vs 
              Commercial Polymeric Membrane
              </h2>

              <div>
                <img src={CMC_1_Image1}/><br/>
                At the heart of our solid state lithium metal battery technology is the proprietary solid electrolyte/separator (SSE), CMC-1 which is a flexible solid electrolyte separator that is stacked between the anode and cathode. CMC-1 acts as a barrier and prevents lithium dendrites from forming on lithium metal anodes and ultimately snaking between the electrodes during charge cycles causing a short circuit that could lead to a battery fire. In conventional lithium-ion batteries, polymer membranes are used as the separator material using liquid electrolytes and are inadequate failsafe against battery fires and dendrites particularly at high operating temperatures.
                <br/><br/>
                <img src={CMC_1_Image2}/><br/>
                The above picture shows thermal testing of the CMC-1 membrane against a commercial polymeric membrane the is used in liquid electrolyte lithium-ion battery.
                <br/><br/>
                The results show that the CMC-1 Solid State Electrode maintained its structural integrity at elevated temperatures of 200°C with no loss of structural support. The commercial polymeric membrane was observed losing structural integrity at 120°C and subsequently appeared to disintegrate by 200°C. This loss of structural integrity in the current technology can result in a thermal runaway event of the battery due to internal short circuit if the polymeric membrane is breached. Commercialized lithium-ion battery technologies using polymeric membranes require a strict thermal management regiment and typically requires battery management systems to begin limiting battery discharge well below 70°C at the cell level for adequate margin of safety against thermal events.
                <br/><br/>
                Solid UltraBattery’s CMC-1 SSE superior thermal performance at wider temperature ranges ensures better stability while maintaining good ionic conductivity resulting in better capability to prevent internal short circuits. A higher level of product safety can be realized as this configuration will substantially lower the probability of thermal runaway. The CMC-1 SSE batteries will enable battery pack designers to employ lower cost thermal management strategies for failsafe operation of lithium metal batteries.
                </div>
              <br/>
            </>
          }
        />
      </>;
      break;

    case "roadmap":
      content=
      <>
        <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
              Product Roadmap
              </h1>
              <br/>

              <div>
              This roadmap includes the ongoing initiative to develop Volt Carbon's 5Ah 400 Wh/kg battery, which is expected to be
prototyped in the upcoming quarter. The project has received support from the DAIR Green Fund, funded by the
Government of Canada through the Federal Economic Development Agency for Southern Ontario (FedDev Ontario).
Additionally, there are plans in place to develop an all-solid-state battery by 2030. The roadmap encompasses various
key technologies currently being pursued in the battery industry, such as the development of high-nickel cathodes like
NMC 955, further advancements in lithium-ion phosphate technologies (“LFP”), high-voltage electrolyte development,
silicone-infused anode development, and optimization of metal organic framework and ceramic separators.
              </div>
              <br/>

              <div>
              In Q2 2023, the Company upgraded its prototype lab with additional fabrication and cycle test
equipment, exclusively dedicated to producing and testing quantities of coin cells. This equipment has been acquired
to support the objectives outlined in the technology roadmap. Furthermore, the new equipment will facilitate the
development of battery-grade anodes refined from the graphite produced at Volt Carbon's Scarborough plant. This
activity is anticipated to take place in Q4 2023.
              </div>
              <br/>


              <img style={{width:"100%", maxWidth: "unset"}} src={project_roadmap}/>
              <br/>
            </>
          }
        />
      </>;
      break;

    case "management":
      content = 
      <>
        <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
                Management and Technical Team
              </h1>
              <br/>
              <br/>
              <div>
                Solid UltraBattery's management and technical team is comprised of seasoned professionals with a wealth of experience and expertise in engineering, finance, and research. <br/>               <br/>
                The team is led by V-Bond, a Professional Engineer with over 30 years of experience in product development and engineering management. He has successfully commercialized new technologies for companies such as General Electric and Magna International. The team also includes Mr. Marrelli, a Chartered Professional Accountant with over 20 years of financial reporting experience, who serves as the Chief Financial Officer. Additionally, Zhongwei Chen, PhD, a professor in the department of Chemical Engineering at University of Waterloo, brings his expertise in battery and fuel cell technology to the team. With their combined experience and knowledge, the Solid UltraBattery team is well-equipped to develop and commercialize groundbreaking solid electrolyte battery technology.
              </div>
              <div className="PersonalInfo">
                <h2>
                Chief Executive Officer
                </h2>
                <h2>
                V-Bond Lee
                </h2>
                <img src={vbond}/>
                <div>
                  V-Bond is a Professional Engineer with 30+ years of leading edge product development and engineering management. He has successfully developed and commercialized new ground breaking methods and technologies for various companies; including BionX International (VP of Engineering & CTO), Sumitomo Precision Products (Director of Engineering), United Technologies Aerospace Systems (Project Head of Business Aircraft), General Electric (Director of Engineering), and Magna International (Engineering Manager). V-Bond is currently AMP President and Founder.
                  <br/>
                  <br/>
                  V-Bond’s portfolio of product launches include processes and components in Aerospace structures and actuation, automotive electrical systems, Infrastructure components including power plants, drinking/waste water systems and light electric vehicle traction drives. Over the years, V-Bond has patented several applications in both the automotive and aerospace fields.
                  <br/>
                  <br/>
                  He is currently an active Board Member at the Ryerson Institute of Aerospace Design and Innovation, as well as a producing member of the SAE A5 Landing Gear Committee.
                </div>
              </div>

              <div className="PersonalInfo">
                <h2>
                Chief Financial Officer
                </h2>
                <h2>
                Carmelo Marrelli
                </h2>
                <img src={marrelli}/>
                <div>
                  Mr. Marrelli brings more than 20 years of financial reporting experience and brings significant experience and support to the Company. Mr. Marrelli is a Chartered Professional Accountant (CPA, CA, CGA), and a member of the Institute of Chartered Secretaries and Administrators, a professional body that certifies corporate secretaries. He received a Bachelor of Commerce degree from the University of Toronto. Mr. Marrelli is the principal of the Marrelli Group, an organization which has provided accounting, corporate secretarial and regulatory compliance services to listed companies on various exchanges for over twenty years. Mr. Marrelli acts as the Chief Financial Officer to several other issuers listed on the TSX, TSX-V and CSE, as well as non-listed companies, and serves as a director of certain issuers.
                </div>
              </div>

              <div className="PersonalInfo">
                <h2>
                Technology Leader
                </h2>
                <h2>
                Zhongwei (Wei) Chen
                </h2>
                <img src={chen}/>
                <div>
                Zhongwei Chen, PhD, has been a professor in the department of Chemical Engineering at University of Waterloo for over 13 years. His Applied Nanomaterials & Clean Energy lab has pioneered solid electrolyte battery technology for Volt Carbon Technologies Inc.. He is recognized as the world leader in battery and fuel cell technology. He is author of over 380 scientific papers and holder of 30+ U.S./international patents/provisional patents.              </div>
              </div>
            </>
          }
        />
      </>;
      break;    
    case "directors":
      content = 
      <>
        <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
                Board of Directors
              </h1>
              <div className="PersonalInfo">
                <h2>
                Chief Executive Officer, President, and Director
                </h2>
                <h2>
                V-Bond Lee
                </h2>
                <img src={vbond}/>
                <div>
                  V-Bond is a Professional Engineer with 30+ years of leading edge product development and engineering management. He has successfully developed and commercialized new ground breaking methods and technologies for various companies; including BionX International (VP of Engineering & CTO), Sumitomo Precision Products (Director of Engineering), United Technologies Aerospace Systems (Project Head of Business Aircraft), General Electric (Director of Engineering), and Magna International (Engineering Manager). V-Bond is currently AMP President and Founder.
                  <br/>
                  <br/>
                  V-Bond’s portfolio of product launches include processes and components in Aerospace structures and actuation, automotive electrical systems, Infrastructure components including power plants, drinking/waste water systems and light electric vehicle traction drives. Over the years, V-Bond has patented several applications in both the automotive and aerospace fields.
                  <br/>
                  <br/>
                  He is currently an active Board Member at the Ryerson Institute of Aerospace Design and Innovation, as well as a producing member of the SAE A5 Landing Gear Committee.
                </div>
              </div>

              <div className="PersonalInfo">
                <h2>
                Director
                </h2>
                <h2>
                Aiping Yu
                </h2>
                <img src={aiping}/>
                <div>
                Prof. Aiping Yu is a University Research Chair professor in the Department of Chemical Engineering at the University of Waterloo.  The immersing in academia and industry for over 26 years has empowered her with multi-multidisciplinary expertise in nanomaterials development, processing, and chemical functionalization for engineering applications in energy storage and multi-functional polymer composites. Prof. Yu has achieved 9 US patents/provisional patents, 3 books/book chapters, and more than 200 refereed journal articles. Broadly speaking, her research has achieved a significant impact on her field and related industries. She is listed in the 2022 World’s Top 2% Scientist database published by John Ioannidis at Stanford. She is the recipient of two prestigious national awards: the NSERC Steacie Memorial Fellowship and the RSC Rutherford Memorial Award. In 2023, she was recognized as Canada’s Most Powerful Women Top 100.  She is now Canada’s foremost researcher in the design and development of cost-effective nanosized materials for energy storage and polymer applications.                </div>
              </div>

              <div className="PersonalInfo">
                <h2>
                Director and Corporate Secretary
                </h2>
                <h2>
                Glen Nursey
                </h2>
                <img src={nursey}/>
                <div>
                Glen Nursey is an entrepreneur and investor based in Victoria, British Columbia. He has been associated with Volt Carbon Technologies as a board member for three years and as a corporate secretary since November 2022. Along with this, he has also been involved in private businesses and has won a hospitality award.
                </div>
              </div>
            </>
          }
        />
      </>;
      break;    

    case "careers":
      content = 
      <>
          <MainInfo color={"white"}
            contentLeft={
              <>
                <h1>
                  Looking for a new career?
                </h1>
                <br/>

                <div>
                  Solid UltraBattery Inc. (SUB), is a Canadian company founded in 2019, with a new research lab and 
                  development facility located in picturesque Guelph, Ontario – minutes off Highway 6 and the 401. SUB is 
                  owned and operated by Volt Carbon Technologies Inc., a carbon sciences research and development 
                  company. 
                </div>

                <h3>
                  Currently no open positions.
                </h3>

                <p>
                  Please email your CV and cover letter to <a href="mailto:adoria@subattery.com">adoria@subattery.com</a>.
                </p>

              </>
            }
          />  
      </>
      break;

    case "team":
      content=
      <>
                <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
              Our Technical Team
              </h1>
              <br/>

              <img style={{maxWidth : "unset"}} src={team0}/>
            </>
          }
        />
      </>;
      break

    case "media":
      content=
      <>
              <MainInfo color={"white"}
          contentLeft={
            <>
              <h1>
              Media
              </h1>
              <br/>

              <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}>
              <iframe src="https://www.youtube.com/embed/qCIcAFp_dW4??enablejsapi=1&amp;cc_load_policy=0&amp;iv_load_policy=1&amp;loop=0&amp;modestbranding=0&amp;fs=1&amp;playsinline=0&amp;controls=1&amp;color=red&amp;cc_lang_pref=&amp;rel=0&amp;autohide=2&amp;theme=dark&amp;" width="1200" height="600" frameborder="0">
              </iframe>

              </div>
            </>
          }
        />
      </>;
      break

    default:
    case "home":
      content =
      <>
        <TopSplash/>

        <SmallInfo content={
          <>
            <div className="Title">
            Developing the Next Generation of Energy Storage Systems
            </div>
          </>
        }/>

        <MainInfo color={"white"}
          contentLeft={
          <HistoryContent/>
          }
        />

        <SmallInfo content={
          <>
            <h2>Join our newsletter</h2>
            <div className="text">
              Stay up to date with Solid UltraBattery
            </div>
            <form target="_blank" action="https://saintjeancarbon.us3.list-manage.com/subscribe/post?u=50856d7301e453076bdeb1038&id=08e8580b85" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate">
              <div id="mc_embed_signup_scroll">
                  <div className="mc-field-group">
                    <input type="email" placeholder="email" name="EMAIL" className="required email" id="mce-EMAIL" required/>
                    <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                  </div>
                  <div id="mce-responses" className="clear foot">
                    <div className="response" id="mce-error-response"></div>
                    <div className="response" id="mce-success-response"></div>
                  </div>
                  <div aria-hidden="true" style={
                  {
                  position: "absolute",
                  left: "-5000px"
                  }
                  }><input type="text" name="b_92f9402b24f7d21a2a959155d_0b95de1c87" tabIndex="-1"/>
              </div>
              <div className="optionalParent">
                  <div className="clear foot">
                    <input type="submit" value="Join" name="subscribe" id="mc-embedded-subscribe" className="button"/>
                  </div>
              </div>
              </div>
            </form>
          </>
        } />

        {contactContent}
         
      </>;
      break;
    case "contact":
      content =
      <>
        {subsidiaryContent}
        {contactContent}
      </>;
      break;
  }

  return (
    <div className="App">
      <TopMenu currentWindow={currentWindow} setCurrentWindow={setCurrentWindow} />
      {content}
      <Footer/>
    </div>
  );
}

export default App;
